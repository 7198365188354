<template>
  <a-row>
    <spinner v-if="loading" />
    <a-col v-show="!loading" class="height100 flex-1 faq-content">
      <a-tabs
        :active-key="$route.query.tab || 'content'"
        class="main-tabs"
        type="card"
        @change="$router.push({ query: { tab: arguments[0] } })"
      >
        <a-tab-pane key="content">
          <span slot="tab"> <a-icon type="project" />Содержимое </span>

          <!-- lang tabs -->
          <a-row align="middle" class="form_title sm-mb">
            <a-col :span="4" class="form_title"> Язык: </a-col>
            <a-col :span="12">
              <!-- lang translit tabs -->
              <a-row align="middle" class="flex-baseline flex-wrap">
                <lang-tabs v-model="langTab" class="mb-1x" />
                <a-button v-if="langTab === 'oz'" @click.stop="translitAction">
                  Перевести копию на
                  {{ langTab === "uz" ? "кирилицу" : "латиницу" }}
                </a-button>
              </a-row>
            </a-col>
          </a-row>

          <!-- title -->
          <a-row align="middle" class="flex-baseline">
            <a-col :span="4" class="form_title"> Название: </a-col>
            <a-col :span="12" class="sm-mb">
              <a-input
                v-model="form.title[langTab]"
                placeholder="Введите название"
                spellcheck="false"
                class="form_title-input"
              />
              <div v-if="!form.title[langTab]" class="error-text">
                Заполните поле
              </div>
            </a-col>
          </a-row>

          <a-row align="middle" class="flex-baseline">
            <a-col :span="4" class="form_title"> Ссылка: </a-col>
            <a-col :span="12" class="sm-mb">
              <a-input
                v-model="form.url"
                type="url"
                name="linkUrl"
                placeholder="Введите ссылку (Обязательно http:// или https:// )"
                :class="{
                  isInvalidUrl:
                    ($v.form.url.$dirty && !$v.form.url.required) ||
                    ($v.form.url.$dirty && !$v.form.url.url)
                }"
              />
              <small
                v-if="$v.form.url.$dirty && !$v.form.url.required"
                class="error-message"
                >Введите название ссылки
              </small>
              <small v-else-if="!$v.form.url.url" class="error-message"
                >Введите корректную ссылку в которой есть http:// или https://
              </small>
            </a-col>
          </a-row>

          <a-row class="sm-mb">
            <a-col :span="4" class="form_title"> Дата принятие НПА: </a-col>
            <a-col :span="12">
              <input v-model="form.date" type="date" />
            </a-col>
          </a-row>

          <a-row class="sm-mb">
            <a-col :span="4" class="form_title"> рег. № Минюста: </a-col>
            <a-col :span="12">
              <a-input
                v-model="form.number_ministry"
                placeholder="Введите рег. № Минюста"
                spellcheck="false"
              />
              <small
                v-if="
                  $v.form.number_ministry.$dirty &&
                  !$v.form.number_ministry.required
                "
                class="error-message"
                >Введите рег. № Минюста
              </small>
            </a-col>
          </a-row>

          <a-row class="sm-mb">
            <a-col :span="4" class="form_title"> Форма НПА </a-col>
            <a-col :span="12">
              <a-select
                v-model="form.legal_act"
                :filter-option="filterOption"
                option-filter-prop="children"
                show-search
                style="width: 100%"
              >
                <a-select-option
                  v-for="legal in legalActList"
                  :key="legal.id"
                  :value="legal.id"
                >
                  (ID: {{ legal.id }}) {{ legal.title || "-" }}
                </a-select-option>
              </a-select>
              <small
                v-if="
                  $v.form.number_ministry.$dirty &&
                  !$v.form.number_ministry.required
                "
                class="error-message"
                >Выберите форму НПА
              </small>
            </a-col>
          </a-row>

          <a-row class="sm-mb">
            <a-col :span="4" class="form_title"> Орган </a-col>
            <a-col :span="12">
              <a-select
                v-model="form.organ"
                :filter-option="filterOption"
                option-filter-prop="children"
                show-search
                style="width: 100%"
              >
                <a-select-option
                  v-for="legal in organList"
                  :key="legal.id"
                  :value="legal.id"
                >
                  (ID: {{ legal.id }}) {{ legal.title || "-" }}
                </a-select-option>
              </a-select>
              <small
                v-if="
                  $v.form.number_ministry.$dirty &&
                  !$v.form.number_ministry.required
                "
                class="error-message"
                >Выберите орган
              </small>
            </a-col>
          </a-row>

          <a-row>
            <a-col :span="4" class="form_title"> Статус: </a-col>
            <a-switch v-model="form.is_active" />
          </a-row>

          <a-row style="margin-top: 15px">
            <a-col :span="4" class="form_title"> Утратил силу: </a-col>
            <a-switch v-model="form.is_invalidated" />
          </a-row>
        </a-tab-pane>
      </a-tabs>

      <a-row class="form-buttons__action" type="flex">
        <a-col :span="6" style="text-align: right; padding-right: 8px" />

        <a-col>
          <a-button
            :disabled="pending"
            @click="
              $router.push({
                name: 'npa'
              })
            "
          >
            <a-icon type="close" />Отмена
          </a-button>

          <a-button
            :loading="pending"
            style="margin-left: 10px"
            type="primary"
            @click="submit((noRouterBack = true))"
          >
            <a-icon type="check" />
            {{ form.id ? "Сохранить" : "Добавить" }}
          </a-button>

          <a-button
            v-if="form.id"
            :loading="pending"
            style="margin-left: 10px"
            type="primary"
            @click="submit((noRouterBack = false))"
          >
            <a-icon type="check" />
            {{ form.id ? "Сохранить и выйти" : "Добавить" }}
          </a-button>
        </a-col>
      </a-row>
    </a-col>
  </a-row>
</template>

<script>
import { required, url } from "vuelidate/lib/validators"
import cloneDeep from "lodash/cloneDeep"
export default {
  data: () => ({
    langTab: $langPrefix,
    pending: false,
    loading: false,
    form: {
      title: {
        uz: "",
        oz: "",
        ru: "",
        en: "",
        qr: ""
      },
      normative_legal_act: null,
      legal_act: null,
      organ: null,
      date: null,
      number_ministry: null,
      url: "",
      is_active: false
    },
    legalActList: [],
    organList: []
  }),
  validations: {
    form: {
      url: {
        required,
        url
      },
      legal_act: {
        required
      },
      organ: {
        required
      },
      number_ministry: {
        required
      }
    }
  },
  async mounted() {
    this.loading = true
    this.fetchData()
    try {
      let res = await this.$api.get("/admin/common/legal-act/list/")
      let resOrgan = await this.$api.get("/admin/common/organ/list/")
      this.legalActList = res.data
      this.organList = resOrgan.data
    } catch (error) {
      console.error(error)
    }
    this.loading = false
  },
  methods: {
    async fetchData() {
      if (!this.$route.params.id) {
        return
      }
      this.loading = true
      try {
        const r = await this.$api.get(
          "/admin/current-regulation/regulations/" +
            this.$route.params.id +
            "/detail/"
        )
        this.form = {
          ...r.data,
          title: {
            ru: r.data && r.data.title_ru,
            en: r.data && r.data.title_en,
            oz: r.data && r.data.title_oz,
            uz: r.data && r.data.title_uz,
            qr: r.data && r.data.title_qr
          }
        }
      } catch (error) {
        console.error(error)
        this.loading = false
      }

      this.loading = false
    },
    // tabPaneChangeHandler(arg) {
    //   this.tabPane = arg
    // },

    translitAction() {
      try {
        const from = this.langTab,
          to = this.langTab === "uz" ? "oz" : "uz",
          methodName = this.langTab === "uz" ? "toKirill" : "toLatin"

        if (this.form.title[from]) {
          this.form.title[to] = this[methodName](this.form.title[from])
        }
      } catch (error) {
        console.log("translitAction error", error)
      }
    },

    async submit(noRouterBack) {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      this.pending = true
      try {
        let newData = cloneDeep(this.form)
        newData = {
          ...newData,
          title_ru: newData.title.ru,
          title_uz: newData.title.uz,
          title_oz: newData.title.oz,
          title_en: newData.title.en,
          title_qr: newData.title.qr,
          normative_legal_act: this.$route.params.laws_id
        }
        delete newData.title
        let f = new FormData()

        Object.keys(newData).forEach((key) => {
          f.append(key, newData[key])
        })

        const createOrUpdate = {
          url:
            this.form && this.form.id
              ? "/admin/current-regulation/regulations/" +
                this.form.id +
                "/update/"
              : "/admin/current-regulation/regulations/create/",
          method: this.form && this.form.id ? "patch" : "post",
          data: f
        }
        const r = await this.$api[createOrUpdate.method](
          createOrUpdate.url,
          createOrUpdate.data
        )
        if ((r && r.status === 200) || (r && r.status === 201)) {
          this.$message.success("Данные сохранены")
          this.$router.push({ name: "npa" })
        }
        if (!noRouterBack) {
          this.$message.success("Данные сохранены")
          this.$router.push({
            name: "npa"
          })
        }
        console.log(r)
      } catch (e) {
        console.log(e)
        this.$message.error("Ошибка при сохранении")
        console.error(e)
        this.pending = false
      }

      this.pending = false
    }
  }
}
</script>
